/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";
// import bgAdmin from "assets/images/body-background.png";
import bgAdmin from "assets/images/wall1.jpg";
import bgAdmin2 from "assets/images/wall2.jpg";
// import bgAdmin from "assets/images/background-card-reports.png";

const { info, dark } = colors;
export default {
  html: {
    scrollBehavior: "smooth",
    background: dark.body,
  },
  body: {
    // background: `url(${bgAdmin})`,
    // background: `#2c3e50`,
    // background: `white`,
    // backgroundImage: `linear-gradient(-225deg , #42275a, #734b6d);`,
    // backgroundImage: `linear-gradient(-60deg, #3B2667 100%, #BC78EC 100%);`,
    // background: `linear-gradient( 179deg,  rgba(0,0,0,1) 0%, rgba(127,16,16,1) 103.9% );`,

    // background: `linear-gradient( 179deg,  rgba(0,0,0,.8) 0%, rgba(173,138,25,.8) 103.9% )`,
    background: `linear-gradient(to bottom left,#216e93,#21648a,#1f5881,#172d5b,#191c51)`,
    // backgroundImage: `linear-gradient( 179deg,  rgba(0,0,0,1) 9.2%, rgba(68,50,41,1) 103.9% );`,
    // backgroundImage: `linear-gradient( 109.6deg,  rgba(68,50,41,1) 11.2%, rgba(83,63,52,1) 47.7%, rgba(153,86,15,1) 100.2% );`,
    // backgroundImage: `radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(69,35,65,1) 0%, rgba(41,30,80,1) 100%);`,
    // backgroundImage: `linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);`,
    backgroundSize: "cover",
    backgroundAttachment: 'scroll',
    // animation: 'gradiente 51s ease infinite',
    minHeight: '100vh',
  },
  "@keyframes gradiente": {
    "0%": {
      backgroundPositdion: "50% 0%"
    },
    "50%": {
      backgroundPosition: "50% 100%"
    },
    "100%": {
      backgroundPosition: "50% 0%"
    }
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".active, .active:hover": {
    animation: "glow 1s infinite alternate"
  },
  "@keyframes glow": {
    "from": {
      "boxShadow": "0 0 2px 0px #3BEDE740"
    },
    "to": {
      "boxShadow": "0 0 2px 10px #3BEDE730"
    }
  },
  ".disabled": {
    cursor: 'auto !important'
  }
};

