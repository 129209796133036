/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Vision UI Dashboard React Helper Function
import rgba from "assets/theme/functions/rgba";

const { black, gradients } = colors;
const { card, cardLight, cardDark } = gradients;
const { borderWidth, borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      background: cardLight.main,
      // backdropFilter: "blur(120px)",
      position: "relative",
      minWidth: 0,
      paddingY: "2px",
      wordWrap: "break-word",
      // backgroundClip: "border-box",
      // border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: xxl,
      transition: "background 0.3s linear", // Optional: Add a smooth transition effect
      '&:hover': {
        background: cardLight.main,
        animation: 'shake 0.3s', // Apply the keyframe animation on hover
      },
      '@keyframes shake': {
        "0%": { transform: 'translate(0px, 1px)'},
        "20%": { transform: 'translate(-1px, 0px)'},
        "40%": { transform: 'translate(-3px, 0px)'},
        "60%": { transform: 'translate(-2px, 0px)'},
        "80%": { transform: 'translate(-1px, 0px)'},
        "100%": { transform: 'translate(-1px, 0px)'},
        // "55%": { transform: 'translate(-3px, 0px)', opacity: '1'},
        // "70%": { transform: 'translate(3px, 0px)', opacity: '1'},
        // "75%": { transform: 'translate(-1px, 0px)', opacity: '1'},
        // "90%": { transform: 'translate(1px, 0px)', opacity: '1'},
        // "100%": { transform: 'translate(1px, 0px)', opacity: '1'}    
      }
    },
  },
};
