/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Paper, Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { Redirect } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { TbHeartRateMonitor } from "react-icons/tb";
import { TbDeviceHeartMonitor } from "react-icons/tb";
import { TbDeviceHeartMonitorFilled } from "react-icons/tb";
import { FaVest } from "react-icons/fa";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// MediaQuery for current screen size info
import useMediaQuery from '@mui/material/useMediaQuery'

// Dashboard layout components
import LineChart from "examples/Charts/LineCharts/LineChart";
import LineChartInside from "examples/Charts/LineCharts/LineChartInside";
import { lineChartDataDashboard } from "./data/lineChartData";
// import { lineChartOptionsOverview } from "./data/lineChartOptions";
import { lineChartOptionsOverview } from "./data/lineChartOptions";
// React icons

// Data
import PieChart from "examples/Charts/PieCharts/PieChart";
import GaugeComponent from 'react-gauge-component'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CountUp from 'react-countup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GiNetworkBars } from "react-icons/gi";
import './test.css'
import Logo from 'assets/images/mobisense_icon.svg';
import Trace from 'assets/images/trace2.svg'
import ORan from 'assets/images/oran.png';
import Drawer from '@mui/material/Drawer';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { position } from "stylis";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { random } from "chroma-js";
import { useRef } from "react";

async function checkAuthStatus() {
  try {
    const response = await fetch('http://localhost:8000/api/v1/admin/verify', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("user", JSON.stringify(data))
      return true; // User is authenticated
    }
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    throw new Error('Not authenticated');
  } catch (error) {
    return false; // Not authenticated
  }
}

async function getDevices(setDevices) {
  try {
    const response = await fetch('http://localhost:8000/api/v1/5g-network-intellegence/current-ues', {
      method: 'GET',
      // TODO: add authentication on this endpoint
      // headers: {
      //   'Authorization': `Bearer ${localStorage.getItem('token')}`
      // } 
    });
    if (response.ok) {
      let data = await response.json();
      data = data.map(item => String(item))
      setDevices(data)
    }
  } catch (error) {
    return false; // Not authenticated
  }
}


function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [devices, setDevices] = useState([])
  const scrollRef = useRef(null);
  const [devicesDb, setDevicesDb] = useState([
    "999700000082500",
    "999700000082501",
    "999700000082502",
    "999700000082503",
    "999700000082504",
    "999700000082505",
  ])
  const [selectedImsi, setSelectedImsi] = useState(null)
  const [anchor, setAnchor] = useState("bottom")
  const [series, setSeries] = useState([{ data: [] }]);
  const now = new Date();
  const nowB = new Date().getTime();
  let count = 0;
  const last5Minutes = new Date(now.getTime() - 5 * 60 * 1000); // 5 minutes in milliseconds
  const last3Minutes = new Date(now.getTime() - 3 * 60 * 1000); // 5 minutes in milliseconds
  const [overviewHistorical, setOverviewHistorical] = useState({
    activeUe: [],
    tpUl: [],
    tpDl: [],
    bsrUl: [],
    bsrDl: [],
    pofpUl: [],
    pofpDl: [],

  })
  const [devicesData, setDevicesData] = useState({
    "999700000082500": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
    "999700000082501": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
    "999700000082502": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
    "999700000082503": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
    "999700000082504": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
    "999700000082505": {
      cqi: [],
      mcsUl: [],
      mcsDl: [],
      tpUl: [],
      tpDl: [],
      bsrUl: [],
      bsrDl: [],
      pofpUl: [],
      pofpDl: [],
      snr: [],
    },
  }) 
  const [timeline, setTimeline] = useState(lineChartOptionsOverview)
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


   
  const [alignment, setAlignment] = useState({
    cqi: 'down',
    mcs: 'up',
    tp: 'up',
    bsr: 'up',
    pfp: 'up'
  });
  const [overview, setOverview] = useState({
    activeUe: 0,
    downCqi: 0,
    upMcs: 0,
    downMcs: 0,
    upTp: 0,
    downTp: 0,
    upBsr: 0,
    downBsr: 0,
    upPof: 0,
    downPof: 0
  })
    

     // Generate series data for the last 5 minutes, with a value every 30 seconds
     const seriesData = [];
     for (let i = 0; i <= 10; i++) { // 10 intervals of 30 seconds in 5 minutes
       const time = last5Minutes + i * 30 * 1000; // Increment by 30 seconds for each point
       const value = 2; // Example: Random value for demonstration
       seriesData.push([time, value]);
     }
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    setState({ ...state, [anchor]: open });
  };

  const list = () => (
    <Box
      className="totooo"
      sx={{ width: '100% !important' }}
      role="presentation"
      onClick={(e) => toggleDrawer(false, e)}
      onKeyDown={(e) => toggleDrawer(false, e)}
    >   <div className="px-8 pt-4 text-left ">
        <VuiTypography color="white">Device: {selectedImsi}</VuiTypography>
        </div>
      <div className="px-8 py-4 text-center ">
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12} xl={12}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md">
        <VuiTypography color='white'>CQI (DOWN-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#008ffb'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].cqi
            }]}  
        />
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>MCS (DOWN-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#2ecc71'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].mcsDl
            }]}  
        />
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>MCS (UP-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#2ecc71'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].mcsDl
            }]}  
        />
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>Throughput (DOWN-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#e74c3c'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].tpDl
            }]}  
        />
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>Throughput (UP-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#e74c3c'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].tpUl
            }]}  
        />
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>BSR (DOWN-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#f1c40f'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].bsrDl
            }]}  
        /> 
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>BSR (UP-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#f1c40f'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].bsrUl
            }]}  
        /> 
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
            <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
        <VuiTypography color='white'>Packets failed (DOWN-LINK)</VuiTypography>
        <LineChartInside
            lineColor='#8e44ad'
            now={nowB}
            lineChartData={[{
              data: selectedImsi && devicesData[selectedImsi].pofpDl
            }]}  
        /> 
        </div>
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
              <VuiTypography color='white'>Packets failed (UP-LINK)</VuiTypography>
              <LineChartInside
                  lineColor='#8e44ad'
                  now={nowB}
                  lineChartData={[{
                    data: selectedImsi && devicesData[selectedImsi].pofpUl
                  }]}  
              /> 
              </div>
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <div style={{backgroundColor: '#1d1836B0', borderRadius: 4}} className="p-2 mb-5 rounded-md shadow-md h-24">
              <VuiTypography color='white'>SNR (UP-LINK)</VuiTypography>
                <LineChartInside
                  lineColor='#7ed6df'
                  now={nowB}
                  lineChartData={[{
                    data: selectedImsi && devicesData[selectedImsi].snr
                  }]}  
              />
              </div>
            </Grid>
        </Grid>
      </div>
    </Box>
  );

  useEffect(() => {
    async function verifyAuthentication() {
      
      if(!localStorage.getItem('token')){
        setIsAuthenticated(false);
        setIsLoading(false);
      }
      const authStatus = await checkAuthStatus();
      setIsAuthenticated(authStatus);
      setIsLoading(false);
    }

    verifyAuthentication();

    getDevices(setDevices);
    const getOverviewData = async () => {
      // console.log("Started Heavy: ", new Date().getTime());
      const activeUe = 'http://localhost:8000/api/v1/5g-network-intellegence/total-connected-ues';
      
      const ulPofp = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-pofp';
      const dlPofp = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-pofp';

      const dlCqi = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-median-cqi';

      const ulMcs = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-median-mcs';
      const dlMcs = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-median-mcs';

      const ulTp = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-avg-throughput';
      const dlTp = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-avg-throughput';

      const ulBsr = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-avg-bsr';
      const dlBsr = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-avg-bsr';
      const individual = 'http://localhost:8000/api/v1/5g-network-intellegence/ue-metrics/999700000082500,999700000082501,999700000082502,999700000082503,999700000082504,999700000082505'

      try {
        const responses = await Promise.all([
            fetch(activeUe),
            fetch(ulPofp),
            fetch(dlPofp),
            fetch(dlCqi),
            fetch(ulMcs),
            fetch(dlMcs),
            fetch(ulTp),
            fetch(dlTp),
            fetch(ulBsr),
            fetch(dlBsr),
            fetch(individual)
        ]);

        const results = await Promise.all(
            responses.map(response => response.json())
        );

        // Assuming results[0] is the response from url1, results[1] from url2, etc.
        setOverview(state => ({...state, activeUe: results[0] ?? 0}))
        setOverview(state => ({...state, upPof: results[1] ?? 0}))
        setOverview(state => ({...state, downPof: results[2] ?? 0}))
        setOverview(state => ({...state, downCqi: results[3] ?? 0}))
        setOverview(state => ({...state, upMcs: results[4] ?? 0}))
        setOverview(state => ({...state, downMcs: results[5] ?? 0}))
        setOverview(state => ({...state, upTp: results[6] ?? 0}))
        setOverview(state => ({...state, downTp: results[7] ?? 0}))
        setOverview(state => ({...state, upBsr: results[8] ?? 0}))
        setOverview(state => ({...state, downBsr: results[9] ?? 0}))
        // ... set other state variables

      setOverviewHistorical(prevData => ({
          ...prevData,
          activeUe: [...prevData.activeUe, [Date.now(), results[0]]],
          tpUl: [...prevData.tpUl, [Date.now(), results[6] !== null ? results[6].toFixed(2) : 0]],
          tpDl: [...prevData.tpDl, [Date.now(), results[7] !== null ? results[7].toFixed(2) : 0]],
          bsrUl: [...prevData.bsrUl, [Date.now(), results[8] !== null ? results[8].toFixed(2) : 0]],
          bsrDl: [...prevData.bsrDl, [Date.now(), results[9] !== null ? results[9].toFixed(2) : 0]],
          pofpUl: [...prevData.pofpUl, [Date.now(), results[1] !== null ? results[1].toFixed(2) : 0]],
          pofpDl: [...prevData.pofpDl, [Date.now(), results[2] !== null ? results[2].toFixed(2) : 0]],
        }));
        console.log(results[10])
        if(results[10].hasOwnProperty("999700000082500") && results[10].hasOwnProperty("999700000082502")){
          console.log("found")
        setDevicesData(prev => ({
          ...prev,
          "999700000082500": {...prev[999700000082500],
            cqi: [...prev[999700000082500].cqi, [Date.now(), results[10]["999700000082500"].cqi !== null ? results[10]["999700000082500"].cqi.toFixed(2) : 0]],
            mcsUl: [...prev[999700000082500].mcsUl, [Date.now(), results[10]["999700000082500"].ul_mcs !== null ? results[10]["999700000082500"].ul_mcs.toFixed(2) : 0]],
            mcsDl: [...prev[999700000082500].mcsDl, [Date.now(), results[10]["999700000082500"].dl_mcs !== null ? results[10]["999700000082500"].dl_mcs.toFixed(2) : 0]],
            tpUl: [...prev[999700000082500].tpUl, [Date.now(), results[10]["999700000082500"].ul_brate_kbps !== null ? results[10]["999700000082500"].ul_brate_kbps.toFixed(2) : 0]],
            tpDl: [...prev[999700000082500].tpDl, [Date.now(), results[10]["999700000082500"].dl_brate_kbps !== null ? results[10]["999700000082500"].dl_brate_kbps.toFixed(2) : 0]],
            bsrUl: [...prev[999700000082500].bsrUl, [Date.now(), results[10]["999700000082500"].ul_bsr !== null ? results[10]["999700000082500"].ul_bsr.toFixed(2) : 0]],
            bsrDl: [...prev[999700000082500].bsrDl, [Date.now(), results[10]["999700000082500"].dl_bs !== null ? results[10]["999700000082500"].dl_bs.toFixed(2) : 0]],
            pofpUl: [...prev[999700000082500].pofpUl, [Date.now(), results[10]["999700000082500"].ul_nof_nok !== null ? results[10]["999700000082500"].ul_nof_nok.toFixed(2) : 0]],
            pofpDl: [...prev[999700000082500].pofpDl, [Date.now(), results[10]["999700000082500"].dl_nof_nok !== null ? results[10]["999700000082500"].dl_nof_nok.toFixed(2) : 0]],
            snr: [...prev[999700000082500].snr, [Date.now(), results[10]["999700000082500"].pusch_snr_db !== null ? results[10]["999700000082500"].pusch_snr_db.toFixed(2) : 0]],
          },
          "999700000082502": {...prev[999700000082502],
            cqi: [...prev[999700000082502].cqi, [Date.now(), results[10]["999700000082502"].cqi !== null ? results[10]["999700000082502"].cqi.toFixed(2) : 0]],
            mcsUl: [...prev[999700000082502].mcsUl, [Date.now(), results[10]["999700000082502"].ul_mcs !== null ? results[10]["999700000082502"].ul_mcs.toFixed(2) : 0]],
            mcsDl: [...prev[999700000082502].mcsDl, [Date.now(), results[10]["999700000082502"].dl_mcs !== null ? results[10]["999700000082502"].dl_mcs.toFixed(2) : 0]],
            tpUl: [...prev[999700000082502].tpUl, [Date.now(), results[10]["999700000082502"].ul_brate_kbps !== null ? results[10]["999700000082502"].ul_brate_kbps.toFixed(2) : 0]],
            tpDl: [...prev[999700000082502].tpDl, [Date.now(), results[10]["999700000082502"].dl_brate_kbps !== null ? results[10]["999700000082502"].dl_brate_kbps.toFixed(2) : 0]],
            bsrUl: [...prev[999700000082502].bsrUl, [Date.now(), results[10]["999700000082502"].ul_bsr !== null ? results[10]["999700000082502"].ul_bsr.toFixed(2) : 0]],
            bsrDl: [...prev[999700000082502].bsrDl, [Date.now(), results[10]["999700000082502"].dl_bs !== null ? results[10]["999700000082502"].dl_bs.toFixed(2) : 0]],
            pofpUl: [...prev[999700000082502].pofpUl, [Date.now(), results[10]["999700000082502"].ul_nof_nok !== null ? results[10]["999700000082502"].ul_nof_nok.toFixed(2) : 0]],
            pofpDl: [...prev[999700000082502].pofpDl, [Date.now(), results[10]["999700000082502"].dl_nof_nok !== null ? results[10]["999700000082502"].dl_nof_nok.toFixed(2) : 0]],
            snr: [...prev[999700000082502].snr, [Date.now(), results[10]["999700000082502"].pusch_snr_db !== null ? results[10]["999700000082500"].pusch_snr_db.toFixed(2) : 0]],
          }
        }))
      }
      
    } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors, maybe set some error state
    }
    // console.log("Ended Heavy: ", new Date().getTime());
    }
    // Execute the function after 5 seconds of page load
    const timeoutId = setTimeout(() => {
      count++;
        setTimeline(prev => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            min: nowB + 60000 *count,
            max: nowB + 60000 *(5+count),
          }
        }));
      // Execute the function every one minute after the initial 5 seconds
      const interv = setInterval(() => {
        count++;
        setTimeline(prev => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            min: nowB + 60000 *count,
            max: nowB + 60000 *(5+count),
          }
        }));
      }, 60000)
      
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(interv); // Clear the interval
    }, 60000*5); // 5000 milliseconds = 5 seconds

  
    const getNewData = async() => {
      const activeUe = 'http://localhost:8000/api/v1/5g-network-intellegence/total-connected-ues';
      const ulPofp = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-pofp';
      const dlPofp = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-pofp';

      const dlCqi = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-median-cqi';

      const ulMcs = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-median-mcs';
      const dlMcs = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-median-mcs';

      const ulTp = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-avg-throughput';
      const dlTp = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-avg-throughput';

      const ulBsr = 'http://localhost:8000/api/v1/5g-network-intellegence/ul-avg-bsr';
      const dlBsr = 'http://localhost:8000/api/v1/5g-network-intellegence/dl-avg-bsr';

      try {
      // Perform your API request here
      const response = await fetch();

      // const newDataPoint = [Date.now(), data];
      // Update state by adding new data point
      setOverviewHistorical(prevData => ({
          ...prevData,
          activeUe: [...prevData.activeUe, [Date.now(), Math.floor(Math.random() * 7)]]
        }));
      console.log(overview)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    }
    // getNewData();
    // const intervalId = setInterval(() => {
    //   getNewData();
    // }, 1000); // 3 seconds

    getOverviewData();

    const interval = setInterval(() => {
      getOverviewData();
      getDevices(setDevices);
    }, 1000); // 60,000 milliseconds = 1 minute
    // const scrollInterval = setInterval(() => {
    //   // console.log("Started: ", new Date().getTime());
    //   if (scrollRef.current) {
    //     console.log(scrollRef.current.scrollTop = scrollRef.current.scrollTop)
    //     scrollRef.current.scrollTop = scrollRef.current.scrollTop;
    //   }
    // }, 250)

    // Clean up the interval on component unmount
    return () => {
      clearTimeout(timeoutId);
      clearInterval(scrollInterval)
      clearInterval(interval)
    };
    
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const borderRadius = 1; // cards' border radius

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }
  return (
    <>
    {isAuthenticated ? (
          
    <DashboardLayout>
      <DashboardNavbar devices={devicesDb} setDevices={setDevicesDb} />
      <VuiBox py={3}>
      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={12} sm={12} md={6} data-aos="fade-right">
        <Typography variant="h4" sx={{ mb: 0, color: 'white !important' }}>
            <span style={{filter:'drop-shadow(2px 2px 5px #95a5a6)'}}>
            <GiNetworkBars />
            </span>
            <span style={{ marginLeft: 10, textShadow:'2px 2px 5px #95a5a6'}}>Network Overview</span>
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
            <span style={{textShadow:'2px 2px 5px #95a5a6'}}>
            Real-time 5G network intellegance monitoring
            </span>
          </VuiTypography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} data-aos="fade-right">
        <div className="flex" style={{justifyContent: 'end'}}>
        {/* <img src={Trace} width={"300px"} style={{marginLeft: 3, display: 'block'}} /> */}
        <img src={Logo} width={"40px"}/>
        <VuiTypography
              variant="button"
              textGradient={true}
              color="logo"
              fontSize={14}
              letterSpacing={2}
              fontWeight="medium"
              sx={
                ((theme) => sidenavLogoLabel(theme, { miniSidenav }),
                {
                  opacity: 1,
                  maxWidth: "100%",
                  margin: "0 10px",
                  fontWeight: 'bold',
                  fontSize: 32,
                  textAlign: 'right',
                  position: 'relative',
                  right: 0,
                  
                })
              }
            >
              <span style={{color: 'white'}}>
              MOBiSENSE AI
              </span>
            </VuiTypography>
        </div>
        </Grid>
        
        
      </Grid>
      </VuiBox>
      
      <VuiBox mb={3}>
      
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3}>
      <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
          <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1.7 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="capitalize" color="light" fontWeight="light" mb="5px">
              active ues
              <Tooltip title="Number of currently active users." sx={{opacity: 0.5}}>
              <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
              </Tooltip>
            </VuiTypography>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "10px" }}>
              <VuiBox sx={{ height: "85%", display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
              <VuiTypography variant="bttton" color="white" fontWeight="bold" sx={{width: '100%', fontSize: 32, textAlign: 'center', position: 'relative'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', direction: 'rtl', padding: '0 50px 0 0'}}>
              <p>{overview.activeUe}</p>
              {/* <CountUp
                    start={0}
                    end={overview.activeUe}
                    duration={0}
                    separator=" "
                    decimals={0}
                    color="white"
                    style={{position: 'relative', zIndex: 1}}
              /> */}
              <div style={{width: '90%'}}>
              <LineChart
                lineChartOptions={timeline}
                lineChartData={[{
                  data: overviewHistorical.activeUe
                }]}
              />
              </div>
              </div>
              </VuiTypography>
              </VuiBox>
            </VuiBox>
          </Card>
      </Grid>

        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
        <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="" color="light" fontWeight="light" mb="5px">
                  Channel Quality Indicator
                  <Tooltip title="(CQI) Indicates the quality of the communication channel. (0-15)" sx={{opacity: 0.5}}>
                  <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
                  </Tooltip>
            </VuiTypography>
            <ToggleButtonGroup
                color="primary"
                value={alignment.cqi}
                exclusive
                onChange={(e) => {setAlignment((state) => ({...state, cqi: e.target.value}))}}
                aria-label="Platform"
                disabled
              >
                <ToggleButton value="up" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Up-Link</ToggleButton>
                <ToggleButton value="down" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Down-Link</ToggleButton>
            </ToggleButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "10px" }}>
              <VuiBox sx={{ height: "100%", width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
              <GaugeComponent
                  type="semicircle"
                  arc={{
                    colorArray: ['#3BEDE7', '#3B4AED'],
                    padding: 0.02,
                    subArcs:
                      [
                        { limit: 5
                        },
                        { limit: 10 },
                        { limit: 15 },
                      ]
                  }}
                  pointer={{type: "blob", animationDelay: 0 }}
                  value={overview.downCqi}
                  maxValue={15}
                />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
        <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="" color="light" fontWeight="light" mb="5px">
              Modulation & Coding Scheme
              <Tooltip title="(MCS) Describes how data is encoded and modulated for transmission. (0-28)" sx={{opacity: 0.5}}>
              <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
              </Tooltip>
            </VuiTypography>
            <ToggleButtonGroup
                color="primary"
                value={alignment.mcs}
                exclusive
                onChange={(e) => {setAlignment((state) => ({...state, mcs: e.target.value}))}}
                aria-label="Platform"
              >
                <ToggleButton value="up" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Up-Link</ToggleButton>
                <ToggleButton value="down" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Down-Link</ToggleButton>
            </ToggleButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "10px" }}>
              <VuiBox sx={{ height: "100%", width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
              <GaugeComponent
                  type="semicircle"
                  arc={{
                    colorArray: ['#3BEDE7', '#3B4AED'],
                    padding: 0.02,
                    subArcs:
                      [
                        { limit: 5
                        },
                        { limit: 10 },
                        { limit: 28 },
                      ]
                  }}
                  pointer={{type: "blob", animationDelay: 0 }}
                  value={alignment.mcs == 'up' ? overview.upMcs : overview.downMcs}
                  maxValue={28}
                />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
        <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="" color="light" fontWeight="light" mb="5px">
              Throughput
              <Tooltip title="Measures the amount of data transferred over the network." sx={{opacity: 0.5}}>
              <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
              </Tooltip>
            </VuiTypography>
            <ToggleButtonGroup
                color="primary"
                value={alignment.tp}
                exclusive
                onChange={(e) => {setAlignment((state) => ({...state, tp: e.target.value}))}}
                aria-label="Platform"
              >
                <ToggleButton value="up" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Up-Link</ToggleButton>
                <ToggleButton value="down" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Down-Link</ToggleButton>
            </ToggleButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "10px" }}>
            <div style={{display: 'flex', overflow: 'hidden', justifyContent: 'space-between', alignItems: 'center', direction: 'rtl', padding: '0 00px 0 0'}}>
            <VuiBox sx={{ width: '25%', height: "auto", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="bttton" color="white" fontWeight="bold" sx={{width: '100%', fontSize: 32, textAlign: 'center'}}>
              <p>{alignment.tp === 'up' ? overview.upTp.toFixed(2) : overview.downTp.toFixed(2)}</p>
              {/* <CountUp
                    start={alignment.tp === 'up' ? overview.downTp : overview.upTp}
                    end={alignment.tp === 'up' ? overview.upTp : overview.downTp}
                    duration={1}
                    separator=" "
                    decimals={1}
                    color="white"
              /> */}
              </VuiTypography>
              <VuiTypography variant="bttton" color="text" fontWeight="light" sx={{fontSize: 12, textAlign: 'center', marginLeft: 0.5, lineHeight: 0.5}}>
                KBPS
              </VuiTypography>
              </VuiBox>
              <div style={{width: '90%'}}>
              <LineChart
                lineChartOptions={timeline}
                lineChartData={[{
                  data: alignment.tp === 'up' ? overviewHistorical.tpUl : overviewHistorical.tpDl 
                }]}
              />
              {/* <LineChart
                lineChartOptions={{
                  chart: {
                    type: 'line',
                    height: 350,
                    toolbar:{
                      show: false
                    }
                  },
                  title: {
                    text: ''
                  },
                  tooltip:{
                    enabled: false,
                  },
                  grid: {
                    show: false, // This shows the grid (both horizontal and vertical)
                    xaxis: {
                      lines: {
                        show: false // This shows vertical grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false // This hides horizontal grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    }
                  },
                  xaxis: {
                    min: last5Minutes.getTime(), // 5 minutes ago
                    max: now.getTime(), // Current time
                    type: 'datetime',
                    tickAmount: 10, // 10 intervals for 5 minutes at 30 seconds each
                    axisBorder: {
                      show: false // Hides the x-axis line
                    },
                    axisTicks: {
                      show: false
                    },
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                    opacity: 0.4,
                  },
                  dataLabels: {
                    enabled: false
                  },
                  markers: {
                    hover: {
                      sizeOffset: 4
                    }
                  }
                }
              }
                lineChartData={[{
                  data: [[last5Minutes.getTime(), 59], [last3Minutes.getTime(), 30], [now.getTime(), 97,73]]
                }]}
              /> */}
              </div>
              </div>
              
            </VuiBox>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
        <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="capitalize" color="light" fontWeight="light" mb="5px">
              Buffer Status Report
              <Tooltip title="Indicates the buffer occupancy status of UEs." sx={{opacity: 0.5}}>
              <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
              </Tooltip>
            </VuiTypography>
            <ToggleButtonGroup
                color="primary"
                value={alignment.bsr}
                exclusive
                onChange={(e) => {setAlignment((state) => ({...state, bsr: e.target.value}))}}
                aria-label="Platform"
              >
                <ToggleButton value="up" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Up-Link</ToggleButton>
                <ToggleButton value="down" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Down-Link</ToggleButton>
            </ToggleButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "10px" }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', direction: 'rtl', padding: '0 0px 0 0'}}>
            <VuiBox sx={{ width: '25%', height: "auto", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="bttton" color="white" fontWeight="bold" sx={{fontSize: 32, textAlign: 'center'}}>
              <span>{alignment.bsr === 'up' ? overview.upBsr.toFixed(2) : overview.downBsr.toFixed(2)}</span>
              {/* <CountUp
                    start={alignment.bsr === 'up' ? overview.downBsr : overview.upBsr}
                    end={alignment.bsr === 'up' ? overview.upBsr : overview.downBsr}
                    duration={1}
                    decimals={2}
                    color="white"
              /> */}
              </VuiTypography>
              <VuiTypography variant="bttton" color="text" fontWeight="light" sx={{fontSize: 12, textAlign: 'center', marginLeft: 1}}>
                B
              </VuiTypography>
              </VuiBox>
              <div style={{width: '75%'}}>
              {/* <LineChart
                lineChartOptions={{
                  chart: {
                    type: 'area',
                    height: 350,
                    toolbar:{
                      show: false
                    }
                  },
                  title: {
                    text: ''
                  },
                  tooltip:{
                    enabled: false,
                  },
                  grid: {
                    show: false, // This shows the grid (both horizontal and vertical)
                    xaxis: {
                      lines: {
                        show: false // This shows vertical grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false // This hides horizontal grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    }
                  },
                  xaxis: {
                    min: last5Minutes.getTime(), // 5 minutes ago
                    max: now.getTime(), // Current time
                    type: 'datetime',
                    tickAmount: 10, // 10 intervals for 5 minutes at 30 seconds each
                    axisBorder: {
                      show: false // Hides the x-axis line
                    },
                    axisTicks: {
                      show: false
                    },
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                    opacity: 0.4,
                  },
                  dataLabels: {
                    enabled: false
                  },
                  markers: {
                    hover: {
                      sizeOffset: 4
                    }
                  }
                }
              }
                lineChartData={[{
                  data: [[last5Minutes.getTime(), 90], [last3Minutes.getTime(), 70], [now.getTime(), 85]]
                }]}
              /> */}
              <LineChart
                lineChartOptions={timeline}
                lineChartData={[{
                  data: alignment.bsr === 'up' ? overviewHistorical.bsrUl : overviewHistorical.bsrDl
                }]}
              />
              </div>
              </div>
              
            </VuiBox>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
        <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid rgba(0, 0, 0, 0.2)", px: "10px", py: 1 }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="capitalize" color="light" fontWeight="light" mb="5px">
              % of failed packets
              <Tooltip title="The percentage of packets that failed to be transmitted successfully." sx={{opacity: 0.5}}>
              <InfoOutlined sx={{marginLeft: 1, cursor: 'pointer'}} color="info" />
              </Tooltip>
            </VuiTypography>
            <ToggleButtonGroup
                color="primary"
                value={alignment.pfp}
                exclusive
                onChange={(e) => {setAlignment((state) => ({...state, pfp: e.target.value}))}}
                aria-label="Platform"
              >
                <ToggleButton value="up" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Up-Link</ToggleButton>
                <ToggleButton value="down" sx={{ color : 'gray !important', '&.Mui-selected': {color:'white !important'}}}>Down-Link</ToggleButton>
            </ToggleButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "200px", px: "0px" }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', direction: 'rtl', padding: '0 0 0 0'}}>
            <VuiBox sx={{ width: '25%', height: "auto", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="bttton" color="white" fontWeight="bold" sx={{width: '100%', fontSize: 32, textAlign: 'center'}}>
              <span>{alignment.pfp === 'up' ? overview.upPof.toFixed(2) : overview.downPof.toFixed(2)}</span>
              </VuiTypography>
              <VuiTypography variant="bttton" color="text" fontWeight="light" sx={{width: '100%', fontSize: 12, textAlign: 'center', marginLeft: 1}}>
                %
              </VuiTypography>
              </VuiBox>
              <div style={{width: '75%'}}>
              {/* <LineChart
                lineChartOptions={{
                  chart: {
                    type: 'line',
                    height: 350,
                    toolbar:{
                      show: false
                    }
                  },
                  title: {
                    text: ''
                  },
                  tooltip:{
                    enabled: false,
                  },
                  grid: {
                    show: false, // This shows the grid (both horizontal and vertical)
                    xaxis: {
                      lines: {
                        show: false // This shows vertical grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false // This hides horizontal grid lines
                      },
                      labels: {
                        style: {
                          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                          // You can specify an array of colors if you have multiple labels and want different colors for each.
                        }
                      },
                    }
                  },
                  xaxis: {
                    min: last5Minutes.getTime(), // 5 minutes ago
                    max: now.getTime(), // Current time
                    type: 'datetime',
                    tickAmount: 10, // 10 intervals for 5 minutes at 30 seconds each
                    axisBorder: {
                      show: false // Hides the x-axis line
                    },
                    axisTicks: {
                      show: false
                    },
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                        // You can specify an array of colors if you have multiple labels and want different colors for each.
                      }
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                    opacity: 0.4,
                  },
                  dataLabels: {
                    enabled: false
                  },
                  markers: {
                    hover: {
                      sizeOffset: 4
                    }
                  }
                }
              }
                lineChartData={[{
                  data: [[last5Minutes.getTime(), 0], [last3Minutes.getTime(), 1], [now.getTime(), 2]]
                }]}
              /> */}
              <LineChart
                lineChartOptions={timeline}
                lineChartData={[{
                  data: alignment.pfp === 'up' ? overviewHistorical.pofpUl : overviewHistorical.pofpDl
                }]}
              />
              </div>
              </div>
             
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={12} sm={6} md={8} data-aos="fade-right">
        <Typography variant="h4" sx={{ mb: 0, color: 'white !important' }}>
            <span style={{position: 'relative', top: 4, filter:'drop-shadow(2px 2px 5px #95a5a6)'}}>
            <TbDeviceHeartMonitorFilled />
            </span>
            <span style={{ marginLeft: 10, textShadow:'2px 2px 5px #95a5a6'}}>Device Monitor</span>
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
          <span style={{textShadow:'2px 2px 5px #95a5a6'}}>
            Monitor connection for individual devices
          </span>
          </VuiTypography>
        </Grid>
      </Grid>
      </VuiBox>
      <VuiBox mb={3}>
      <Grid container spacing={1} justifyItems={'center'} justifyContent="center" marginBottom={3} data-aos="fade-left">
        {devicesDb.length > 0 ? devicesDb.map((dev, ind) => (
        <Grid key={ind} justifyItems={'center'} item xs={12} sm={12} md={4} sx={{margin: "10px 0px"}}>
          <Card
            className={devices.includes(dev) ? 'active': 'disabled'}
            sx={{width: '90%', borderRadius: 50, cursor: "pointer", backgroundColor: devices.includes(dev) ? '#3BEDE770' :'', '&:hover': {backgroundColor: devices.includes(dev) ? '#3BEDE790 !important': '#1d1b4199 !important'}}}
            onClick={ (e) => {
              toggleDrawer(true, e);
              setSelectedImsi(dev);
            }
            }
          >
            <VuiBox sx={{px: "10px", py: 1, textAlign: 'center' }}>
            <VuiTypography variant="md" fontSize="9px !important" textTransform="uppercase" color="light" fontWeight="bold" mb="5px">
              {dev === '999700000082505' ? <FaVest /> : <PhoneIphoneIcon />}
              {" "+dev}
            </VuiTypography>
            </VuiBox>
          </Card>
        </Grid>
        ))
      :
      <p>There are no connected devices right now :(</p>
      }
      </Grid>
      </VuiBox>
      </VuiBox> 
      <Drawer
        PaperProps={{
          sx: {
            width: "auto",
            backgroundColor: 'rgb(43 36 163 / 20%)!important',
            height: '90%'
          },
          ref: scrollRef,
        }}
        anchor={anchor}
        open={state[anchor]}
        onClose={(e) => toggleDrawer(false, e)}
      >
        {list()}
      </Drawer>
      
      <Footer />
    </DashboardLayout>
        ) : (
          <></>
          // <Redirect to={{ pathname: "/authentication/sign-in"}} />
        )
    }
    </>
  );
}

export default Dashboard;
