/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const now = new Date();
    const last5Minutes = new Date(now.getTime() - 5 * 60 * 1000); // 5 minutes in milliseconds
    const last3Minutes = new Date(now.getTime() - 3 * 60 * 1000); // 5 minutes in milliseconds
export const lineChartDataDashboard = [{
  data: [[last5Minutes.getTime(), 0], [last3Minutes.getTime(), 1], [now.getTime(), 2]]
}];
