/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChartInside extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }
  
  render() {
    return (
      <ReactApexChart
        options={{
          colors: [this.props.lineColor],
          chart: {
            type: 'area',
            height: 50,
            toolbar:{
              show: false
            }
          },
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            },
          },
          title: {
            text: ''
          },
          tooltip:{
            enabled: false,
          },
          grid: {
            show: true, // This shows the grid (both horizontal and vertical)
            borderColor: '#FFFFFF20',
            xaxis: {
              lines: {
                show: false, // This shows vertical grid lines
                style: {
                  colors: 'red'
                }
              },
              labels: {
                style: {
                  colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                  // You can specify an array of colors if you have multiple labels and want different colors for each.
                }
              },
            },
            yaxis: {
              lines: {
                show: true // This hides horizontal grid lines
              },
              labels: {
                style: {
                  colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
                  // You can specify an array of colors if you have multiple labels and want different colors for each.
                }
              },
            }
          },
          fill: {
            type: "gradient",
            // gradient: {
            //   shade: "dark",
            //   type: "vertical",
            //   shadeIntensity: 0,
            //   gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            //   inverseColors: true,
            //   opacityFrom: 0.3,
            //   opacityTo: 0.1,
            //   stops: [],
            // },
          },
          xaxis: {
            // min: this.props.now.getTime(), // 5 minutes ago
            // max: this.props.now.getTime() + 1000*60*5, // Current time
            type: 'datetime',
            tickAmount: 10, // 10 intervals for 5 minutes at 30 seconds each
            axisBorder: {
              show: true, // Hides the x-axis line
              color: '#FFFFFF50'
            },
            axisTicks: {
              show: false
            },
            labels: {
              style: {
                colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                // You can specify an array of colors if you have multiple labels and want different colors for each.
              }
            },
          },
          yaxis: {
            axisBorder: {
              show: true, // Hides the x-axis line
              color: '#FFFFFF50'
            },
            labels: {
              style: {
                colors: '#FFFFFF', // Changes the label colors to red on the x-axis
                // You can specify an array of colors if you have multiple labels and want different colors for each.
              }
            },
          },
          stroke: {
            curve: 'smooth',
            opacity: 0.4,
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            hover: {
              sizeOffset: 4
            }
          }
        }}
        series={this.props.lineChartData}
        type="area"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChartInside;
