/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayout from "../components/BasicLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import VuiAlert from "components/VuiAlert";

import { useHistory } from 'react-router-dom'; // Import useHistory


function SignIn() {
  // added username password values
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [rememberMe, setRememberMe] = useState(true);
  const history = useHistory(); // Initialize useHistory
  useEffect(() => {
    if(localStorage.getItem("token")){
      history.push("/tourist-engagement");
    }
  })
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Call your function here
      handleSignin();
    }
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSignin = async() => {
    setErrorMessage("")
    console.log(username, password)
    try {
      const response = await fetch('http://localhost:8000/api/v1/admin/token', { // Replace with your FastAPI endpoint
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              username,
              password
          })
      });

      if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
          setErrorMessage("Invalid credentials, please try again!")
      }

      const data = await response.json();
      localStorage.setItem('token', data.access_token)
      history.push('/tourist-engagement')
      // <Redirect from="*" to="/tourist-engagement" />

      // Here you can handle the received token (e.g., store it in local storage, state management, etc.)
      // localStorage.setItem('token', data.access_token);

  } catch (error) {
      console.error('SignIn failed:', error);
      setErrorMessage('Invalid Credentials, please try again.');
  }
  };

  return (
    <CoverLayout
      title="Nice to see you!"
      color="white"
      description="Enter your email and password to sign in"
      premotto="INSPIRED BY THE FUTURE:"
      motto="MOBISENSE AI"
      image={bgSignIn}
    >
      {errorMessage !== "" && <VuiAlert
        color="error"
        fontSize="12px"
        dismissible
      >
        {errorMessage}
      </VuiAlert>}
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Username
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput 
            type="email"
            placeholder="Your email..."
            fontWeight="500"
            value={username}
            onChange={(e) => {setUsername(e.target.value)}}
            onKeyPress={handleKeyPress}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={password}
              onChange={(e) => {setPassword(e.target.value)}}
              onKeyPress={handleKeyPress}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="error" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton 
            color="error"
            fullWidth
            onClick={handleSignin}
          >
            SIGN IN
          </VuiButton>
        </VuiBox>
        {/* no need for signup now */}
        {/* <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox> */}
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
