/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const now = new Date();
const last5Minutes = new Date(now.getTime() - 5 * 60 * 1000); // 5 minutes in milliseconds
const last3Minutes = new Date(now.getTime() - 3 * 60 * 1000); // 5 minutes in milliseconds
export const lineChartOptionsOverview = {
  chart: {
    height: 10,
    toolbar:{
      show: false
    }
  },
  animations: {
    enabled: true,
    easing: 'linear',
    dynamicAnimation: {
      speed: 1000
    },
  },
  title: {
    text: ''
  },
  tooltip:{
    enabled: false,
  },
  grid: {
    show: false, // This shows the grid (both horizontal and vertical)
    xaxis: {
      lines: {
        show: false // This shows vertical grid lines
      },
      labels: {
        style: {
          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
          // You can specify an array of colors if you have multiple labels and want different colors for each.
        }
      },
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 2,
      lines: {
        show: false // This hides horizontal grid lines
      },
      labels: {
        style: {
          colors: ['#FFFFFF'], // Changes the label colors to red on the x-axis
          // You can specify an array of colors if you have multiple labels and want different colors for each.
        }
      },
    }
  },
  xaxis: {
    min: now.getTime(), // 5 minutes ago
    max: now.getTime() + 1000*60*5, // Current time
    type: 'datetime',
    forceNiceScale: true,
    tickAmount: 4, // 10 intervals for 5 minutes at 30 seconds each
    axisBorder: {
      show: false // Hides the x-axis line
    },
    axisTicks: {
      show: false
    },
    labels: {
      style: {
        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
        // You can specify an array of colors if you have multiple labels and want different colors for each.
      }
    },
  },
  yaxis: {
    // min:0,
    // max: 6,
    labels: {
      style: {
        colors: '#FFFFFF', // Changes the label colors to red on the x-axis
        // You can specify an array of colors if you have multiple labels and want different colors for each.
      }
    },
  },
  stroke: {
    curve: 'smooth',
    opacity: 0.4,
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    hover: {
      sizeOffset: 4
    }
  }
};

